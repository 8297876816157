import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Breadcrumb, Row, notification } from "antd";
import { useNavigate } from "react-router-dom";

const Users = (props) => {
  //   const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.BEACH}`,
            title: "User",
          },
        ]}
      />

      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Users.getAll,
            // extraQuery: { userRole: 0 },
            // extraQuery: { "role[ne]": "user" },

            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                role_name : el?.role?.name,
                phone_number : `${el?.countryCode ?? ''} ${el?.phone ?? "-"}`,
                currency_name : el?.preferredCurrency?.name,
                // no: `${el?.id}`.padStart(6, "0"),
                // view: {
                //   onClick: () => {
                //     if (el?.role === "user") {
                //       return notification.info({
                //         message: "This Section is not available to user",
                //       });
                //     }
                //     navigate(`${el?.id}`, "_blank");
                //   },
                //   id: el?.id,
                // },
                isBlocked: {
                  checked: !el?.inactive,
                  id: el.id,
                  onClick: (id, val) => {
                    const UpdateAPIEnd = { ...CONSTANTS?.API.Users.update };
                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    API.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        Setrefresh((prev) => !prev);
                      },
                      { inactive: val },
                      `User ${val ? "blocked" : "Unblocked"} Successfully`,()=>{}
                  ,"Currently Not working We will update Soon"  );
                  },
                },
              }));
            },
            column: CONSTANTS.TABLE.USERS,
          }}
          // UPDATE={{
          //   API: CONSTANTS.API.Users.update,
          //   message: "Updated User successfully",
          //   modaltitle: "Update User",
          //   modalFields: CONSTANTS.FORM_FIELD.USERS_MODAL,
          //   payloadModifier: (payload, data) => {
          //     if (payload?.password && data?.password === payload?.password) {
          //       delete payload?.password;
          //     }
          //     return payload;
          //   },
          // }}
          CREATE={{
            API: CONSTANTS.API.Users.create,
            message: "Created Admin successfully",
            modaltitle: "Add Admin",
            modalFields: CONSTANTS.FORM_FIELD.ADMIN_MODAL,
            // payloadModifier: (res) => res,
            name: "Add a Admin",
          }}
          DELETE={{
            API: CONSTANTS.API.Users.delete,
            message: "Soft Delete only  Deleted Successfully",
          }}
            FILTERSORTKEY={{
              role_name: "role_id"}}
          isSearch
          selectionOff
          datefilter
          props={props}
        />
      </Row>
    </>
  );
};

export default Users;
