import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import Services from "../../util/API/service";
import QuillToggleFullscreenButton from "quill-toggle-fullscreen-button/dist/quill.toggleFullscreenButton";

// #2 register module
Quill.register("modules/toggleFullscreen", QuillToggleFullscreenButton);
Quill.register("modules/imageUploader", ImageUploader);
const modules = {
  // toolbar: [
  //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //   ["bold", "italic", "underline", "strike", "blockquote"],
  //   [
  //     { list: "ordered" },
  //     { list: "bullet" },
  //     // { indent: "-1" },
  //     // { indent: "+1" },
  //   ],
  //   ["link"],
  //   // ["clean"],
  // ],
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      { color: [] }, // ["#000", "#fff", "#00aae3"]
      { background: [] }, // ["#000", "#fff", "#00aae3", "#00ff00"]
    ], // dropdown with defaults from theme
    // [{ font: [] }],
    [{ align: [] }],
    ["link", "code-block", "image"], // remove formatting button
  ],
  toggleFullscreen: true,
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);
        Services.post("/admin/blog/images", formData)
          // .then((response) => response.json())
          .then((result) => {
            console.log(result, result.data?.data?.imageUrl);
            resolve(result.data?.data?.imageUrl);
          })
          .catch((error) => {
            reject("Upload failed");
            console.error("Error:", error);
          });
      });
    },
  },
};

const formats = [
  // "header",
  // "bold",
  // "italic",
  // "underline",
  // "strike",
  // "blockquote",
  // "list",
  // "bullet",
  // "indent",
  // "link",
  // // "code",
  // // "image",
  // // "imageBlot",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "script",
  "indent",
  "direction",
  "color",
  "background",
  "align",
  "link",
  "image",
  "code-block",
];

const TextEditorSimple = ({ value, onChange, placeholder }) => {
  return (
    <>
      <ReactQuill
        // style={{ height: "100%", width: "100%" }}
        theme="snow"
        // scrollingContainer={false}
        // className={value == undefined ? "ql-error" : null}
        value={value || ""}
        modules={modules}
        formats={formats}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  );
};

export default TextEditorSimple;
