import { Navigate, Route } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { VscDashboard } from "react-icons/vsc";
import { MdCardGiftcard, MdSettings } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import Users from "../page/app/user";
import SignUp from "../page/user/signup";
import ForgetPassword from "../page/user/password/ForgetPassword";
import ResetPassword from "../page/user/password/ResetPassword";
import Setting from "../page/app/setting";
// import Setting from "../page/app/setting";
// import Poster from "../page/app/poster";
// import PosterIdWise from "../page/app/poster/Id";

export const loginRoot = "/";

export const appRoot = "/app";

export const ROUTES = {
  app: "/app",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/passwordReset",
  DASHBOARD: "dashboard",
  BEACH: "allbeach",
  LISTICLES: "listicles",
  USERS: "users",
  USERS_DETAIL: "users/:id",
  SETTINGS: "settings",
  POSTER: "poster",
  CATEGORY: "category",
  TAG: "tag",
  BLOG: "blog",
  LOCATION: "location",
};

export const ROLES = {
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

const LOGIN_ROUTES = [
  {
    index: true,
    element: <LogIn />,
  },
  // {
  //   path: "/signup",
  //   element: <SignUp />,
  // },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <ResetPassword />,
  },
];

const ALL_ROUTES = (appProps) => [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout {...appProps} />,
    Role: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
    // label: "Dashboard ",

    children: [
      {
        index: true,
        element: (
          <Navigate
            to={`${ROUTES.DASHBOARD}`}
            {...appProps}
            appProps={appProps}
          />
        ),
      },
      {
        path: `${ROUTES.DASHBOARD}`,
        element: <Dashboard {...appProps} appProps={appProps} />,
        icon: <VscDashboard />,
        label: "Dashboard",
        Role: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      },
      {
        path: `${ROUTES.USERS}`,
        element: <Users {...appProps} appProps={appProps} />,
        icon: <FaUser />,
        label: "User",
        Role: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      },
      {
        path: `${ROUTES.SETTINGS}`,
        element: <Setting {...appProps} appProps={appProps} />,
        icon: <MdSettings />,
        label: "Setting",
        Role: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    path: "/error",
    element: <Error {...appProps} />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize {...appProps} />,
  },
  {
    path: "*",
    element: <Navigate to="/error" {...appProps} />,
  },
];

export default ALL_ROUTES;
