import React, { useEffect } from "react";
import { Button, Card, Col, Form, Image, Input, Row } from "antd";
import useHttp from "../../../hooks/use-http";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { getAuthToken, setAuthDetails } from "../../../util/API/authStorage";
import { ROUTES, appRoot } from "../../../util/Route";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Link } from "react-router-dom";

const LogIn = () => {
  const API = useHttp();

  const validateLogin = (value) => {
    const payload = {
      email: value.email,
      password: value.password,
    };
    // localStorage.setItem("IDPASS", JSON.stringify(payload));
    // window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);
    // setAuthDetails("Alpha beta gone");
    // window.location.assign(appRoot);
    API.sendRequest(
      CONSTANTS.API.login,
      (res) => {
        if (res?.token) {
          setAuthDetails(res?.token);
          window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);
        }
        // console.log(res)
      },
      payload,
      "LogIn Successful"
    );
  };

  useEffect(() => {
    // if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
    //   return;
    // }
    // window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);

    if (
      getAuthToken() !== undefined &&
      getAuthToken() !== null &&
      getAuthToken()
    ) {
      window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);
    }
  }, []);
  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 h-screen ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 ">
          <Image
            // src="https://static.vecteezy.com/system/resources/previews/000/606/474/original/vector-letter-a-logo-design-concept-template.jpg"
            src={"/icon.png"}
            alt="Moulk"
            preview={false}
            width={120}
            className="my-5 bg-gray-50 rounded-full"
          />
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-2xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Log In
              </h1>
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={validateLogin}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please Enter Your Email!",
                    },
                  ]}
                  hasFeedback
                  // label="Email"
                  // labelCol="sm"
                  // validateFirst
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    type="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    autoComplete=""
                  />
                </Form.Item>
                {/* <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </a>
                </div> */}
                <div className="flex items-center justify-between mb-3">
                  <Link
                    to={ROUTES.FORGOT_PASSWORD}
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </Link>
                  {/* <Link
                    to={ROUTES.RESET_PASSWORD}
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Reset password?
                  </Link> */}
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={API?.isLoading}
                    block
                    // disabled
                  >
                    Log in
                  </Button>
                </Form.Item>
                {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don’t have an account yet?{" "}
                  <a
                    href="#"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign up
                  </a>
                </p> */}
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LogIn;
