import { Alert, Card, Col, Row, Statistic, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { FaUser } from "react-icons/fa";
import { BsTrophyFill } from "react-icons/bs";
import { MdOutlineCategory, MdOutlineWorkspacePremium } from "react-icons/md";
import UserChart from "../../../component/UserChart";

const Dashboard = (props) => {
  const [data, setData] = useState({});
  const [chartdata, setChartData] = useState([]);
  const DETAILS = [
    {
      title: "Total Users",
      count: data?.users ?? 0,
      icon: <FaUser className=" text-5xl text-[#046ee5] " />,
    },
    {
      title: "Total Premium Users",
      count: data?.totalPremiumUsers ?? 0,
      icon: <MdOutlineWorkspacePremium className=" text-5xl text-[#046ee5] " />,
    },
    {
      title: "Total Goals",
      count: data?.goals ?? 0,
      icon: <BsTrophyFill className=" text-5xl text-[#046ee5] " />,
    },
    {
      title: "Total Categories",
      count: data?.categories ?? 0,
      icon: <MdOutlineCategory className=" text-5xl text-[#046ee5] " />,
    },
    // { title: "Total Subscription", count: data?.totalSubscription ?? 0  },
    // { title: "Total Categories", count: data?.totalCategories?? 0  },
    // { title: "Total Collection", count: data?.totalCollection?? 0 },
    // {
    //   title: "Total Google Ads",
    //   count: data?.totalFiveStarPhoto || 5623,
    // },
    // { title: "Total No. Of Photos", count: data?.totalMedia || 0 },
    // { title: "Total Pro Photos", count: data?.totalProPhoto || 0 },
  ];
  const API = useHttp();

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.anaytics, (res) => {
      setData(res?.data);
    });
    API.sendRequest(CONSTANTS.API.anayticsGraph, (res) => {
      setChartData(res?.data);
    });
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} className="mt-6">
        {/* <Col xs={24}>
      <Alert showIcon description="Data is Static will replce once API of analytics is ready" />
      </Col> */}
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            key={el?.title + i}
          >
            <Card size="small" className="p-5 ">
              <div className="flex items-center gap-2">
                {el?.icon}
                <Statistic title={el?.title} value={el?.count} />
              </div>
            </Card>
          </Col>
        ))}
        <Col span={24}>
          <Card>
            <Typography.Title
              level={3}
            >{`New User (Monthly)`}</Typography.Title>
            <UserChart data={chartdata} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
