import React, { useEffect } from "react";
import { Button, Card, Col, Form, Image, Input, Row } from "antd";
import useHttp from "../../../hooks/use-http";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { getAuthToken, setAuthDetails } from "../../../util/API/authStorage";
import { ROUTES, appRoot } from "../../../util/Route";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const API = useHttp();

  const validateLogin = (value) => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const payload = {
      // email: value.email,
      password: value.password,
      token,
    };
    console.log(payload);
    // localStorage.setItem("IDPASS", JSON.stringify(payload));
    // window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);
    // setAuthDetails("Alpha beta gone");
    // window.location.assign(appRoot);
    API.sendRequest(
      CONSTANTS.API.resetPass,
      (res) => {
        if (res?.token) {
          setAuthDetails(res?.token);
          window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);
        }
      },
      payload,
      "Reset Password Successful",
      () => {
        window.location.assign("/");
      }
    );
  };

  useEffect(() => {
    // if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
    //   return;
    // }
    // window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);

    if (
      getAuthToken() !== undefined &&
      getAuthToken() !== null &&
      getAuthToken()
    ) {
      window.location.assign(`${appRoot}/${ROUTES.DASHBOARD}`);
    }
  }, []);
  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 h-screen ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 ">
          <Image
            src={"/icon.png"}
            alt="Moulk"
            preview={false}
            width={120}
            className="my-5 bg-gray-50 rounded-full"
          />
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-2xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Reset Password
              </h1>
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={validateLogin}
              >
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please input new password" },
                    {
                      type: "string",
                      min: 8,
                      message: "Password must be at least 8 characters",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="New Password"
                    className="app-input mt5"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="app-input mt5"
                    placeholder="Confirm Password"
                  />
                </Form.Item>
                {/* <div className="flex items-center justify-between mb-3">
                  <Link
                    to={ROUTES.FORGOT_PASSWORD}
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </Link>
                  <Link
                    to={ROUTES.RESET_PASSWORD}
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Reset password?
                  </Link>
                </div> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={API?.isLoading}
                    block
                    // disabled
                  >
                    Reset Password
                  </Button>
                </Form.Item>
                {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don’t have an account yet?{" "}
                  <a
                    href="#"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign up
                  </a>
                </p> */}
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ResetPassword;
