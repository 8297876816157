import React, { useEffect, useState } from "react";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Button, Card, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import FormFields from "../../../component/common/FormFields";
import useHttp from "../../../hooks/use-http";

const Setting = (props) => {
  const { profile, SetProfile } = props;
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [form] = useForm();
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getMe, (res) => {
      // console.log(res, "API");
      CONSTANTS.GETMe = res?.data;
      // setUserData(res?.data);
      SetProfile({ ...res?.data });
    });
  }, [refresh]);
  return (
    <>
      <Card className="mt-8">
        <ModalFormCreator
          open={openResetPassword}
          name="Reset Password"
          onCreate={(v) => {
            console.log(v);
            API.sendRequest(
              CONSTANTS.API.updateMe,
              (res) => {
                setRefresh((prev) => !prev);
                setOpenResetPassword(false);
              },
              {
                password: v?.password,
              },
              "Updated Password Successfully"
            );
          }}
          onCancel={() => {
            setOpenResetPassword(false);
          }}
          menuFields={CONSTANTS.FORM_FIELD.RESET_PASSWORD}
        />
        <ModalFormCreator
          open={openName}
          name="Change Detail"
          onCreate={(v) => {
            // console.log(v);
            API.sendRequest(
              CONSTANTS.API.updateMe,
              (res) => {
                setRefresh((prev) => !prev);
                setOpenName(false);
              },
              v,
              "Updated Profile Successfully"
            );
          }}
          formData={{ ...profile }}
          onCancel={() => {
            setOpenName(false);
          }}
          menuFields={CONSTANTS.FORM_FIELD.USERS}
        />
        {/* <Row> */}
        <FormFields
          formData={{ ...profile }}
          menuFields={CONSTANTS.FORM_FIELD.USERS_MODAL}
          //   formFields={formFields}
          form={form}
          disabled={true}
          //   changedFields={fieldChangeData}
        />
        {/* </Row> */}
        <Row className="mt-4 " justify="space-between">
          <Button
            type="primary"
            onClick={() => {
              setOpenName(true);
            }}
          >
            Change Detail
          </Button>
          <Button
            type="default"
            danger
            onClick={() => {
              setOpenResetPassword(true);
            }}
          >
            Reset Password
          </Button>
        </Row>
      </Card>
    </>
  );
};

export default Setting;
