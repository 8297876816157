import { Column } from "@ant-design/charts";
import React from "react";

const UserChart = ({ data }) => {
  const config = {
    data,
    xField: "month",
    yField: "count",
    style: {
      fill: ({ count }) => {
        // if (count <=5 ) {
        //   return 'red';
        // }
        return "#046ee5";
      },
      maxWidth: 20,
    },
    // label: {
    //   text: (originData) => {
    //     const val = parseFloat(originData.count);
    //     if (val < 0.05) {
    //       return (val * 100).toFixed(1) + '%';
    //     }
    //     return '';
    //   },
    //   offset: 10,
    // },
    scale: {
      y: {
        type: "linear",
      },
    },
    legend: false,
  };
  return <Column {...config} />;
};

export default UserChart;
