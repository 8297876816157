import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Switch,
  notification,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import DummyImage from "../../asset/image/no-cover-image.jpg";
import moment from "moment/moment";
import { FaEye } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";

export const ROLES = {
  ADMIN: "admin",
  SUPER_ADMIN: "super-admin",
};
export const ROLES_ARRAY = [
  {
    id: "e5bbcc98-566e-4895-8bf2-d0c1fb3822b4",
    value: "e5bbcc98-566e-4895-8bf2-d0c1fb3822b4",
    label: "User",
    text: "User",
  },
  {
    id: "admin",
    value: "admin",
    label: "Admin",
    text: "Admin",
  },

  {
    id: "super-admin",
    value: "super-admin",
    label: "Super Admin",
    text: "Super Admin",
  },
];
export const ISPREMIUM = [
  {
    id: true,
    value: true,
    label: "Yes",
    text: "Yes",
  },
  {
    id: false,
    value: false,
    label: "No",
    text: "No",
  },
];
export const alphanumericSort = (a, b, value) => {
  if (a[value] === true || a[value] === false) {
    return a[value] - b[value];
  }
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};
export function convertToPST(dateString, formatearray) {
  // Parse the date using moment
  const date = moment(dateString);

  // // Manually apply the PST offset of UTC-8
  // const pstDate = date.utcOffset(-8);

  const pstDate = date;
  // Return the formatted date string in PST
  if (formatearray) {
    return [pstDate.format("YYYY-MM-DD"), pstDate.format("hh:mm A")];
  }
  return pstDate.format("YYYY-MM-DD hh:mm A ");
}
export const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
export const RenderDeleteButton = (value = {}) => {
  const {
    id,
    onClick,
    message = "Are you sure you want to delete this?",
  } = value;
  return (
    <Popconfirm
      title={
        <p className=" flex items-center justify-center">
          <BsQuestionCircle className=" text-red-600 mx-2" /> {message}
        </p>
      }
      onConfirm={() => onClick(id)}
      align="center"
      icon={null}
    >
      <Button type="primary" danger>
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Are you sure you want to delete this beach?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
export const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
export const RenderEditButton = (value = {}) => {
  const { id = null, onClick = () => {} } = value;
  if (!id) {
    return <></>;
  }
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// View Image Button
export const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={<FaEye />}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
export const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
export const RenderViewButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined
      // onClick={() => {
      //   // onClick(id);
      // }}
      />
    </Button>
  );
};

// Render Images
export const ImageRanders = (value, extraClass) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = DummyImage;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className={`rounded-full object-cover ${extraClass}`}
        src={imageSrc}
        alt={value}
        onError={(e) => {
          e.target.src = DummyImage;
        }}
      />
    </div>
  );
};

// Toggle Button
export const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};
export const RenderInputField = (Upvalue) => {
  const { value, id, onChange } = Upvalue;

  return (
    <div>
      <InputNumber
        // onChange={() => {
        //   onClick(id, checked);
        // }}
        defaultValue={value}
        onBlur={(e) => {
          // console.log("Blur Event happended ", parseInt(e.target.value), id);
          onChange({ value: parseInt(e.target.value), id });
          // } else {
          //   notification.error({ message: "Enter valid Integer Value" });
          // }
        }}
        // onPointerOut={(e) => {
        //   console.log(e, "Pointer Out happended ");
        // }}
        // checked={checked}
      />
    </div>
  );
};

// Checkbox render
export const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

const CONSTANTS = {
  GETMe: null,
  TABLE: {
    POSTER: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Poster Image",
        dataIndex: "posterImage",
        width: 80,
        render: (v) => ImageRanders(v, "rounded-sm"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Slug",
        dataIndex: "slug",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "slug"),
      },
      {
        title: "Height",
        dataIndex: "height",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "height"),
      },
      {
        title: "Width",
        dataIndex: "width",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "width"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    POSTER_TEXT: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "fieldName",
        dataIndex: "fieldName",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "fieldName"),
      },
      {
        title: "X",
        dataIndex: "X",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "X"),
      },
      {
        title: "Y",
        dataIndex: "Y",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "Y"),
      },
      {
        title: "color",
        dataIndex: "color",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "color"),
      },
      {
        title: "size",
        dataIndex: "size",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "size"),
      },
      {
        title: "fontSize",
        dataIndex: "fontSize",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "fontSize"),
      },
      {
        title: "fontFamily",
        dataIndex: "fontFamily",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "fontFamily"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 100,
        // role: [ROLES.SUPER_ADMIN],
      },
    ],
    CATEGORY: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    TAG: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Name",
        dataIndex: "tag",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "tag"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    BEACH: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Last Updated",
        dataIndex: "updatedAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} [PST]`} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.updatedAt).subtract(moment(b.updatedAt)),
        // role: [ROLES.ADMIN],

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    USERS: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      // {
      //   title: "View",
      //   dataIndex: "view",
      //   render: RenderViewButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      //   width: 80,
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 160,
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Active",
        dataIndex: "isBlocked",
        width: 140,
        render: RenderToggleButton,
      },
      {
        title: "Role",
        dataIndex: "role_name",
        width: 140,
        sorter: (a, b) => alphanumericSort(a, b, "role_name"),
        // filters: ROLES_ARRAY,
        // filterMultiple: false,
        // onFilter: (value, record) => true,
        // filterSearch: true,
        render: (v) => {
          return `${v}`.toUpperCase();
        },
      },
      {
        title: "currency",
        dataIndex: "currency_name",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "currency_name"),
      },
      {
        title: "isPremium",
        dataIndex: "isPremium",
        width: 160,
        render: (v) => (v ? "Yes" : "No"),
        // sorter: (a, b) => alphanumericSort(a, b, "isPremium"),
        filters: ISPREMIUM,
        filterMultiple: false,
        onFilter: (value, record) => true,
        filterSearch: true,
      },
      // {
      //   title: "isReminderEnable",
      //   dataIndex: "isReminderEnable",
      //   width: 180,
      //   render : (v)=> v? "Yes" : "No",
      //   filters: ISPREMIUM,
      //   filterMultiple: false,
      //   onFilter: (value, record) => true,
      //   filterSearch: true,
      //   // sorter: (a, b) => alphanumericSort(a, b, "isReminderEnable"),
      // },

      {
        title: "Joining Date",
        dataIndex: "createdAt",
        width: 180,
        render: (v) => {
          const value = convertToPST(v, true);
          return (
            <>
              <p>{value[0]}</p>
              <p>{`${value[1]} `} </p>
            </>
          );
        },
        sorter: (a, b) => moment(a.createdAt).subtract(moment(b.createdAt)),
        // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      //   width: 80,
      //   role: [ROLES.SUPER_ADMIN],
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      //   role: [ROLES.SUPER_ADMIN],
      // },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    USER_BEACH: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      // {
      //   title: "View",
      //   dataIndex: "view",
      //   render: RenderViewButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      //   width: 80,
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={status.value === "DONE"}
            >
              <Select.Option key={"TODO"}>TODO</Select.Option>
              <Select.Option key={"INPROGRESS"}>INPROGRESS</Select.Option>
              <Select.Option key={"DONE"}>DONE</Select.Option>
            </Select>
          );
        },
      },

      // {
      //   title: "Last Updated",
      //   dataIndex: "updatedAt",
      //   width: 180,
      //   render: (v) => {
      //     const value = convertToPST(v, true);
      //     return (
      //       <>
      //         <p>{value[0]}</p>
      //         <p>{`${value[1]} [PST]`} </p>
      //       </>
      //     );
      //   },
      //   sorter: (a, b) => moment(a.updatedAt).subtract(moment(b.updatedAt)),
      //   // render: ImageRanders,
      //   // sorter: (a, b) => alphanumericSort(a, b, "updatedAt"),
      // },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      // },
      // { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],
    LISTICLE: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        width: 80,

        render: (v) => ImageRanders(v, "rounded-sm"),
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   width: 80,
      //   render: RenderEditButton,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    LISTICLE_BEACHES: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      // {
      //   title: "Image",
      //   dataIndex: "image",
      //   width: 80,

      //   render: (v) => ImageRanders(v, "rounded-sm"),
      //   // sorter: (a, b) => alphanumericSort(a, b, "name"),
      // },
      // {
      //   title: "View",
      //   dataIndex: "view",
      //   render: RenderViewButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      //   width: 80,
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      {
        title: "City",
        dataIndex: "cityName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Index",
        dataIndex: "rankInput",
        width: 120,
        render: RenderInputField,

        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "url Count",
        dataIndex: "urlCount",
        width: 110,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
    LOCATION: [
      {
        title: "S. No",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 80,
      },
      // {
      //   title: "Image",
      //   dataIndex: "image",
      //   width: 80,

      //   render: (v) => ImageRanders(v, "rounded-sm"),
      //   // sorter: (a, b) => alphanumericSort(a, b, "name"),
      // },
      {
        title: "Name",
        dataIndex: "name",
        width: 160,

        // render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },

      // {
      //   title: "City",
      //   dataIndex: "cityName",
      //   width: 160,

      //   // render: ImageRanders,
      //   // sorter: (a, b) => alphanumericSort(a, b, "cityName"),
      // },
      {
        title: "State",
        dataIndex: "stateName",
        width: 160,

        // render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "state"),
      },
      {
        title: "Country",
        dataIndex: "countryName",
        width: 160,
        // sorter: (a, b) => alphanumericSort(a, b, "country"),
      },
    ],
    BLOG: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        width: 380,
      },
      {
        title: "Read Time",
        dataIndex: "readTime",
        sorter: (a, b) => alphanumericSort(a, b, "readTime"),
        width: 170,
      },
      {
        title: "Wishlists",
        dataIndex: "wishlists",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "wishlists"),
        width: 120,
      },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "comments"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        // render: (v) =>
        //   RenderDeleteButton({
        //     ...v,
        //     message: "Are you Sure to delete this Category ?",
        //   }),
        render: RenderDeleteButton,
        width: 80,
        role: [ROLES.SUPER_ADMIN],
      },
    ],
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    LISTICLE_BEACHES: [
      {
        no: 1,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter a Description",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Urls",
        name: "urls",
        id: "urls",
        type: "multifield",
        placeholder: "Enter a Urls",
        required: true,
        width: 24,
        removeName: "Remove Url",
        addName: "Add Url",
        AllFields: [
          {
            no: 1,
            Label: "url",
            name: "url",
            id: "url",
            type: "url",
            placeholder: "Enter a url",
            required: true,
            width: 24,
          },
        ],
      },
    ],
    POSTER: [
      {
        no: 1,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Enter a Slug",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "Height",
        name: "height",
        id: "height",
        type: "number",
        placeholder: "Enter a Height",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Width",
        name: "width",
        id: "width",
        type: "number",
        placeholder: "Enter a Width",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "Poster Photo",
        name: "imageFile",
        id: "imageFile",
        type: "file",
        placeholder: "Select Poster Photo",
        required: true,
      },
    ],
    POSTER_EDIT: [
      {
        no: 1,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Enter a Slug",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "Height",
        name: "height",
        id: "height",
        type: "number",
        placeholder: "Enter a Height",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Width",
        name: "width",
        id: "width",
        type: "number",
        placeholder: "Enter a Width",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "Poster Photo",
        name: "imageFile",
        id: "imageFile",
        type: "file",
        placeholder: "Select Poster Photo",
        required: false,
      },
    ],
    POSTER_TEXT: [
      {
        no: 1,
        Label: "fieldName",
        name: "fieldName",
        id: "fieldName",
        type: "text",
        placeholder: "Enter a fieldName [ex: fullName ]",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "X",
        name: "X",
        id: "X",
        type: "text",
        placeholder: "Enter a X  [ex: 40 ]",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Y",
        name: "Y",
        id: "Y",
        type: "text",
        placeholder: "Enter a Y  [ex: 100 ]",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "color",
        name: "color",
        id: "color",
        type: "text",
        placeholder: "Enter a color  [ex: red ]",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "size",
        name: "size",
        id: "size",
        type: "text ",
        placeholder: "Enter a size  [ex: 100 ]",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "fontSize",
        name: "fontSize",
        id: "fontSize",
        type: "text ",
        placeholder: "Enter a fontSize  [ex: 25px ]",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "fontFamily",
        name: "fontFamily",
        id: "fontFamily",
        type: "text",
        placeholder: "Enter a fontFamily  [ex: Arial ]",
        required: true,
        width: 12,
      },
    ],
    POSTER_IMAGE: [
      {
        no: 1,
        Label: "fieldName",
        name: "fieldName",
        id: "fieldName",
        type: "text",
        placeholder: "Enter a fieldName",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "X",
        name: "X",
        id: "X",
        type: "number",
        placeholder: "Enter a X",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Y",
        name: "Y",
        id: "Y",
        type: "number",
        placeholder: "Enter a Y",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "height",
        name: "height",
        id: "height",
        type: "number",
        placeholder: "Enter a height",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "width",
        name: "width",
        id: "width",
        type: "number",
        placeholder: "Enter a width",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "Person Photo",
        name: "imageFile",
        id: "imageFile",
        type: "file",
        placeholder: "Select Person Photo",
        required: true,
      },
    ],
    POSTER_IMAGE_EDIT: [
      {
        no: 1,
        Label: "fieldName",
        name: "fieldName",
        id: "fieldName",
        type: "text",
        placeholder: "Enter a fieldName",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "X",
        name: "X",
        id: "X",
        type: "number",
        placeholder: "Enter a X",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Y",
        name: "Y",
        id: "Y",
        type: "number",
        placeholder: "Enter a Y",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "height",
        name: "height",
        id: "height",
        type: "number",
        placeholder: "Enter a height",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "width",
        name: "width",
        id: "width",
        type: "number",
        placeholder: "Enter a width",
        required: true,
        width: 12,
      },
      // {
      //   no: 4,
      //   Label: "Person Photo",
      //   name: "imageFile",
      //   id: "imageFile",
      //   type: "file",
      //   placeholder: "Select Person Photo",
      //   required: false,
      // },
    ],
    CATEGORY: [
      {
        no: 1,
        Label: "Category",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Category",
        required: true,
        width: 24,
      },
    ],
    TAG: [
      {
        no: 1,
        Label: "Tag",
        name: "tag",
        id: "tag",
        type: "text",
        placeholder: "Enter a Tag",
        required: true,
        width: 24,
      },
    ],
    ADD_COUNTRY_STATE_CITY: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter name",
        required: true,
        width: 24,
      },
      // {
      //   no: 2,
      //   Label: "Select Country , State and City Selector",
      //   name: "Slector",
      //   id: "selector",
      //   type: "citystatecountry",
      //   required: true,
      //   RequiredObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   VisibleObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   fieldFilter: ["Country"],
      // },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: true,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
        },
        fieldFilter: ["State"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
        },
        fieldFilter: ["City"],
      },
      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Meta Title",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Meta Description",
        required: true,
      },
      {
        no: 4,
        Label: "Cover Photo",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "Select Cover Photo",
        required: true,
      },
    ],
    EDIT_COUNTRY_STATE_CITY: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter name",
        required: true,
        width: 24,
      },
      // {
      //   no: 2,
      //   Label: "Select Country , State and City Selector",
      //   name: "Slector",
      //   id: "selector",
      //   type: "citystatecountry",
      //   required: true,
      //   RequiredObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   VisibleObject: {
      //     cityId: false,
      //     stateId: false,
      //     countryId: true,
      //   },
      //   fieldFilter: ["Country"],
      // },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: true,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
        },
        fieldFilter: ["State"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
        },
        fieldFilter: ["City"],
      },
      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Meta Title",
        required: true,
        width: 24,
      },
      {
        no: 3,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Meta Description",
        required: true,
      },
      {
        no: 4,
        Label: "Cover Photo",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "Select Cover Photo",
        required: false,
      },
    ],
    BEACH_USER_RELATION: [
      {
        no: 1,
        Label: "User",
        name: "userId",
        id: "userId",
        type: "select",
        placeholder: "User name",
        option: [],
        required: true,
        width: 12,
      },
      {
        no: 2,
        Label: "Category Name",
        name: "action",
        id: "action",
        type: "select",
        placeholder: "Category name",
        option: [
          { id: "Images", label: "Images", value: "Images" },
          { id: "Cover Image", label: "Cover Image", value: "Cover Image" },
          { id: "Summary", label: "Summary", value: "Summary" },
          {
            id: "Basic Details",
            label: "Basic Details",
            value: "Basic Details",
          },
        ],
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: " Notes",
        name: "notes",
        id: "notes",
        type: "textarea",
        placeholder: "Enter Notes for User",
        required: true,
      },
    ],
    BEACHMAIN_ADD: [
      {
        no: 1,
        Label: "Beach Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Beach Name",
        required: true,
      },
      {
        no: 2,
        Label: "Country , State and City Selector",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: true,
          stateId: true,
          countryId: true,
        },
        VisibleObject: {
          cityId: true,
          stateId: true,
          countryId: true,
        },
      },
      {
        no: 3,
        Label: "Alternate Name",
        name: "altName",
        id: "altName",
        type: "text",
        placeholder: "Enter Alternate Name",
        // required: true,
      },
      {
        no: 4,
        Label: "latitude",
        name: "latitude",
        id: "latitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -90 &&
                parseFloat(value) <= 90
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid latitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter latitude",
        // required: true,
      },
      {
        no: 5,
        Label: "longitude",
        name: "longitude",
        id: "longitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -180 &&
                parseFloat(value) <= 180
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid longitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter longitude",
        // required: true,
      },
      {
        no: 6,
        Label: "rating out of 100",
        name: "rating100",
        id: "rating100",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseInt(value) <= 100 &&
                parseFloat(value) >= 0
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid rating"));
            },
          }),
        ],
        width: 8,
        type: "number",
        placeholder: "Enter rating",
        // required: true,
      },
      {
        no: 7,
        Label: "video",
        name: "droneVideo",
        id: "droneVideo",
        width: 24,
        rule: [
          {
            // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
            pattern:
              /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/,
            message: "Enter valid drone Video url",
          },
        ],
        type: "text",
        placeholder: "Enter Drone Video",
        // required: true,
      },
      {
        no: 8,
        Label: "Address",
        name: "address",
        id: "address",
        // width: 24,
        type: "text",
        placeholder: "Enter Address",
        // required: true,
      },
      // {
      //   no: 3,
      //   Label: "top Fifty",
      //   name: "topFifty",
      //   id: "topFifty",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,
      //   required: true,
      // },
      // {
      //   no: 3,
      //   Label: "favorite",
      //   name: "favorite",
      //   id: "favorite",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,

      //   required: true,
      // },
    ],
    BEACH_LISTICLE: [
      {
        no: 1,
        Label: "Listicle Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Listicle Name",
        required: true,
      },
      {
        no: 2,
        Label: "Select Country ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: false,
          countryId: true,
        },
        VisibleObject: {
          cityId: false,
          stateId: false,
          countryId: true,
        },
        fieldFilter: ["Country"],
      },
      {
        no: 2,
        Label: "Select Country and State ",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: false,
          stateId: true,
          countryId: true,
        },
        VisibleObject: {
          cityId: false,
          stateId: true,
          countryId: true,
        },
        fieldFilter: ["State"],
      },
      {
        no: 2,
        Label: "Select Country , State and City",
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
        RequiredObject: {
          cityId: true,
          stateId: true,
          countryId: true,
        },
        VisibleObject: {
          cityId: true,
          stateId: true,
          countryId: true,
        },
        fieldFilter: ["City"],
      },
      {
        no: 3,
        Label: "Listicle Cover Photo",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "Select Listicle Cover Photo",
        required: true,
      },
      {
        no: 4,
        Label: "Listicle Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Listicle Description",
        required: true,
      },
    ],
    BEACHMAIN_EDIT: [
      {
        no: 1,
        Label: "Beach Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Beach Name",
        required: true,
      },
      {
        no: 32,
        name: "Slector",
        id: "selector",
        type: "citystatecountry",
        required: true,
      },
      {
        no: 1,
        Label: "Alternate Name",
        name: "altName",
        id: "altName",
        type: "text",
        placeholder: "Enter Alternate Name",
        // required: true,
      },
      {
        no: 2,
        Label: "latitude",
        name: "latitude",
        id: "latitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -90 &&
                parseFloat(value) <= 90
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid latitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter latitude",
        // required: true,
      },
      {
        no: 2,
        Label: "longitude",
        name: "longitude",
        id: "longitude",
        width: 8,
        step: "0.0000000000001",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseFloat(value) >= -180 &&
                parseFloat(value) <= 180
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid longitude"));
            },
          }),
        ],
        type: "number",
        placeholder: "Enter longitude",
        // required: true,
      },
      {
        no: 4,
        Label: "rating out of 100",
        name: "rating100",
        id: "rating100",
        rule: [
          () => ({
            validator(_, value) {
              // console.log(value);
              if (
                !isNaN(value) &&
                parseInt(value) <= 100 &&
                parseFloat(value) >= 0
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please Enter valid rating"));
            },
          }),
        ],
        width: 8,
        type: "number",
        placeholder: "Enter rating",
        // required: true,
      },
      {
        no: 3,
        Label: "video",
        name: "droneVideo",
        id: "droneVideo",
        width: 24,
        rule: [
          {
            // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
            pattern:
              /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/,
            message: "Enter valid drone Video url",
          },
        ],
        type: "text",
        placeholder: "Enter Drone Video",
        // required: true,
      },
      {
        no: 2,
        Label: "Address",
        name: "address",
        id: "address",
        // width: 24,
        type: "text",
        placeholder: "Enter Address",
        // required: true,
      },
      // {
      //   no: 3,
      //   Label: "top Fifty",
      //   name: "topFifty",
      //   id: "topFifty",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,
      //   required: true,
      // },
      // {
      //   no: 3,
      //   Label: "favorite",
      //   name: "favorite",
      //   id: "favorite",
      //   width: 4,
      //   type: "switch",
      //   // disabled: true,

      //   required: true,
      // },
    ],
    BEACH_BASIC_EDIT: [
      {
        no: 1,
        Label: "phone Number",
        name: "phoneNumber",
        id: "phoneNumber",
        type: "text",
        // rule: [
        //   () => ({
        //     validator(_, value) {
        //       // console.log(value);
        //       if (value && /^\d{10}$/.exec(value)) {
        //         return Promise.resolve();
        //       }
        //       if (!value) {
        //         return Promise.resolve();
        //       }
        //       return Promise.reject(
        //         new Error("Please Enter valid mobile number")
        //       );
        //     },
        //   }),
        // ],
        width: 9,
        placeholder: "Enter phoneNumber",
        // required: true,
      },
      {
        no: 2,
        Label: "cell Reception",
        name: "cellReception",
        id: "cellReception",
        type: "select",
        option: [
          { id: "Weak", label: "Weak", value: "weak" },
          { id: "Strong", label: "Strong", value: "strong" },
          { id: "Average", label: "Average", value: "average" },
          { id: "Medium", label: "Medium", value: "medium" },
          { id: "Poor", label: "Poor", value: "poor" },
          // {
          //   id: "Not Available",
          //   label: "Not Available",
          //   value: "Not Available",
          // },
          // { id: "private", label: "private", value: "private" },
        ],
        width: 9,
        placeholder: "Enter cell Reception",
        // required: true,
      },
      {
        no: 3,
        Label: "hours",
        name: "hours",
        id: "hours",
        width: 4,
        step: "0.01",
        type: "text",
        placeholder: "Enter hours",
        // required: true,
      },
      {
        no: 2,
        Label: "size",
        name: "size",
        id: "size",
        width: 4,
        step: "0.0001",
        type: "number",
        placeholder: "Enter size",
        Other: {
          addonAfter: (
            <Form.Item name="prefix" noStyle>
              <Select
                style={{
                  width: 110,
                }}
                // defaultValue={"Miles"}
              >
                <Select.Option value="Miles">Miles</Select.Option>
                <Select.Option value="Kilometers">Kilometers</Select.Option>
                <Select.Option value="Meters">Meters</Select.Option>
              </Select>
            </Form.Item>
          ),
        },

        // required: true,
      },
      {
        no: 2,
        Label: "parking",
        name: "parking",
        id: "parking",
        width: 3,
        type: "switch",
        placeholder: "Enter parking",
        // required: true,
      },
      {
        no: 2,
        Label: "entry Fee",
        name: "entryFee",
        id: "entryFee",
        width: 3,
        type: "switch",
        placeholder: "Enter entryFee",
        // required: true,
      },
      {
        no: 2,
        Label: "private or Public",
        name: "privatePublic",
        id: "privatePublic",
        width: 4,
        type: "select",
        option: [
          { id: "public", label: "public", value: "public" },
          { id: "private", label: "private", value: "private" },
        ],
        placeholder: "Enter private or Public",
        // required: true,
      },
      {
        no: 2,
        Label: "sand Color",
        name: "sandColor",
        id: "sandColor",
        width: 12,
        type: "text",
        placeholder: "Enter sand Color",
        // required: true,
      },
      {
        no: 2,
        Label: "sand Texture",
        name: "sandTexture",
        id: "sandTexture",
        width: 12,
        type: "text",
        placeholder: "Enter sand Texture",
        // required: true,
      },

      // {
      //   no: 2,
      //   Label: "Address",
      //   name: "address",
      //   id: "address",
      //   // width: 24,
      //   type: "text",
      //   placeholder: "Enter Address",
      //   // required: true,
      // },
    ],
    BEACH_SUMMARY_EDIT: [
      {
        no: 1,
        Label: "introduction",
        name: "introduction",
        id: "introduction",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter introduction",
        // required: true,
      },
      {
        no: 1,
        Label: "summary",
        name: "summary",
        id: "summary",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter summary",
        // required: true,
      },
      {
        no: 1,
        Label: "activities",
        name: "activities",
        id: "activities",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter activities",
        // required: true,
      },
      {
        no: 1,
        Label: "amenities",
        name: "amenities",
        id: "amenities",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter amenities",
        // required: true,
      },
      {
        no: 1,
        Label: "attraction",
        name: "attraction",
        id: "attraction",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter attraction",
        // required: true,
      },
      {
        no: 1,
        Label: "disability Access",
        name: "disabilityAccess",
        id: "disabilityAccess",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter disabilityAccess",
        // required: true,
      },
      {
        no: 1,
        Label: "fun Fact",
        name: "funFact",
        id: "funFact",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter funFact",
        // required: true,
      },
      {
        no: 1,
        Label: "hotel",
        name: "hotel",
        id: "hotel",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter hotel",
        // required: true,
      },

      {
        no: 1,
        Label: "parking",
        name: "parking",
        id: "parking",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter parking",
        // required: true,
      },
      {
        no: 1,
        Label: "restaurant",
        name: "restaurant",
        id: "restaurant",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter restaurant",
        // required: true,
      },

      // {
      //   no: 1,
      //   Label: "parking",
      //   name: "parking",
      //   id: "parking",
      //   type: "richTextarea",
      //   placeholder: "Enter parking",
      //   required: true,
      // },
      // {
      //   no: 1,
      //   Label: "parking",
      //   name: "parking",
      //   id: "parking",
      //   type: "richTextarea",
      //   placeholder: "Enter parking",
      //   required: true,
      // },
      // {
      //   no: 1,
      //   Label: "parking",
      //   name: "parking",
      //   id: "parking",
      //   type: "richTextarea",
      //   placeholder: "Enter parking",
      //   required: true,
      // },
    ],
    BEACH_SETTING: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "H1 tag",
        name: "h1",
        id: "h1",
        type: "text",
        placeholder: "Enter H1 tag",
        required: true,
      },
      {
        no: 3,
        Label: "keywords",
        name: "keywords",
        id: "keywords",
        type: "text",
        placeholder: "Enter keywords with separated by ', '",
        // required: true,
      },
    ],
    BEACH_LINK_PHOTO_UPLOAD: [
      {
        no: 1,
        Label: "Contributor Name",
        name: "photographerName",
        id: "photographerName",
        type: "text",
        placeholder: "Enter Contributor Name",
        required: true,
      },
      {
        no: 2,
        Label: "Contributor ID",
        name: "photographerLink",
        id: "photographerLink",
        type: "text",
        placeholder: "Enter Contributor ID",
        required: true,
      },
      {
        no: 3,
        Label: "Photo Link",
        name: "imageUrl",
        id: "imageUrl",
        type: "text",
        placeholder: "Enter Photo Link",
        required: true,
        // rule: [
        //   {
        //     // pattern: data?.pattern || /^[a-zA-Z0-9\s]+$/,
        //     pattern:
        //       /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/,
        //     message: "Enter valid drone Video url",
        //   },
        // ],
        extraBelow: (
          <>
            <Form.Item shouldUpdate className="w-full ">
              {({ getFieldValue }) => {
                const imageUrl = getFieldValue("imageUrl");

                // Customize the message or component to display based on validation
                if (!imageUrl) {
                  return null;
                }

                return (
                  <Col xs={24} className="flex justify-center items-center">
                    <div className=" aspect-video w-[400px] flex items-center justify-center">
                      <Image src={imageUrl} />
                    </div>
                  </Col>
                );
              }}
            </Form.Item>
          </>
        ),
      },
    ],
    RESET_PASSWORD: [
      {
        no: 2,
        Label: "Password",
        name: "password",
        id: "password",
        type: "password",
        placeholder: "Enter Password",
        required: true,
      },
      {
        no: 2,
        Label: "confirm Password",
        name: "confirmPassword",
        id: "confirmPassword",
        type: "password",
        placeholder: "Enter confirm Password",
        required: true,
        rule: ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("The two passwords that you entered do not match!")
            );
          },
        }),
      },
    ],
    USERS: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        // width : 8
      },
    ],
    USERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        width: 8,
      },
      {
        no: 2,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter Email",
        required: true,
        width: 8,
      },
      {
        no: 3,
        Label: "Role",
        name: "role",
        id: "role",
        type: "select",
        option: ROLES_ARRAY,
        placeholder: "Select Role",
        required: true,
        width: 8,
      },
      // {
      //   no: 2,
      //   Label: "Password",
      //   name: "password",
      //   id: "password",
      //   type: "password",
      //   placeholder: "Enter Password",
      //   required: true,
      // },
    ],
    ADMIN_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        // width : 8
      },
      {
        no: 2,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter Email",
        required: true,
        // width : 8
      },
      // {
      //   no: 3,
      //   Label: "Role",
      //   name: "role",
      //   id: "role",
      //   type: "select",
      //   option: ROLES_ARRAY,
      //   placeholder: "Select Role",
      //   required: true,
      //   // width : 8

      // },
      {
        no: 2,
        Label: "Password",
        name: "password",
        id: "password",
        type: "password",
        placeholder: "Enter Password",
        required: true,
      },
    ],
    BLOG_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Tags",
        name: "tags",
        id: "tags",
        type: "select",
        placeholder: "Select Tag",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Alt",
      //   name: "alt",
      //   id: "alt",
      //   type: "text",
      //   placeholder: "Enter Alt",
      //   required: true,
      // },
      // {
      //   no: 8,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime: "HH:mm:ss",
      //   format: "YYYY-MM-DD HH:mm:ss",
      //   // required: true,
      // },
    ],
    EDIT_BLOG_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Tags",
        name: "tags",
        id: "tags",
        type: "select",
        placeholder: "Select Tag",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Alt",
      //   name: "alt",
      //   id: "alt",
      //   type: "text",
      //   placeholder: "Enter Alt",
      // },
      // {
      //   no: 9,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime:'HH:mm:ss',
      //   format:"YYYY-MM-DD HH:mm:ss",
      //   required: true,
      //   disabled:true,
      // },
    ],
  },

  API: {
    login: {
      type: "POST",
      endpoint: "/admin-login",
    },
    signUp: { type: "POST", endpoint: "/signup" },
    forgetPass: { type: "POST", endpoint: "/forgot-password" },
    forgetPassAdmin: { type: "POST", endpoint: "/forgot-admin-password" },
    resetPass: { type: "POST", endpoint: "/reset-password" },
    getMe: { type: "GET", endpoint: "/user/profile" },
    updateMe: { type: "PATCH", endpoint: "/user/update-me" },
    overview: { type: "GET", endpoint: "/admin/overview" },
    anaytics: { type: "GET", endpoint: "/analytics" },
    anayticsGraph: { type: "GET", endpoint: "/analytics/user-graph" },
    Poster: {
      getAll: {
        type: "GET",
        endpoint: "/posters",
      },
      create: {
        type: "POST",
        endpoint: "/poster/",
      },
      update: {
        type: "PUT",
        endpoint: "/poster/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/poster/",
      },
      getOne: {
        type: "GET",
        endpoint: "/posters/:id",
      },
    },
    PosterText: {
      getAll: {
        type: "GET",
        endpoint: "/poster/texts",
      },
      create: {
        type: "POST",
        endpoint: "/poster/text/",
      },
      update: {
        type: "PUT",
        endpoint: "/poster/text/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/poster/text/",
      },
      getOne: {
        type: "GET",
        endpoint: "/poster/texts/:id",
      },
    },
    PosterImage: {
      getAll: {
        type: "GET",
        endpoint: "/poster/person_image",
      },
      create: {
        type: "POST",
        endpoint: "/poster/person_image/",
      },
      update: {
        type: "PUT",
        endpoint: "/poster/person_images/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/poster/person_image/",
      },
      getOne: {
        type: "GET",
        endpoint: "/poster/person_image/:id",
      },
    },
    Listicle: {
      getAll: {
        type: "GET",
        endpoint: "/admin/listiclesMain",
      },
      create: {
        type: "POST",
        endpoint: "/admin/listiclesMain/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/listiclesMain/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/listiclesMain/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/listiclesMain/",
      },
    },
    Listicle_Beaches: {
      getAll: {
        type: "GET",
        endpoint: "/admin/listiclesMain/:listicleId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/listiclesBeach/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/listiclesBeach/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/listiclesBeach/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/listiclesBeach/",
      },
    },
    Country: {
      getAll: {
        type: "GET",
        endpoint: "/admin/countries",
      },
      create: {
        type: "POST",
        endpoint: "/admin/countries/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/countries/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/countries/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/countries/",
      },

      getOneBySlug: {
        type: "GET",
        endpoint: "/countries/getBySlug/",
      },
    },
    State: {
      getAll: {
        type: "GET",
        endpoint: "/admin/states",
      },
      create: {
        type: "POST",
        endpoint: "/admin/states/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/states/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/states/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/states/",
      },
    },
    City: {
      getAll: {
        type: "GET",
        endpoint: "/admin/cities",
      },
      create: {
        type: "POST",
        endpoint: "/admin/cities/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/cities/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/cities/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/cities/",
      },
    },
    BeachUser: {
      getAll: {
        type: "GET",
        endpoint: "/user/beach/tag",
      },
      create: {
        type: "POST",
        endpoint: "/admin/user/beach/tag/",
      },
      update: {
        type: "PUT",
        endpoint: "/user/beach/tag/status/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/user/beach/tag/",
      },
      getOne: {
        type: "GET",
        endpoint: "/user/beach/tag/",
      },
    },
    Users: {
      getAll: {
        type: "GET",
        endpoint: "/user",
      },
      create: {
        type: "POST",
        endpoint: "/user",
      },
      update: {
        type: "PUT",
        endpoint: "/user/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/user/",
      },
      getOne: {
        type: "GET",
        endpoint: "/user/:id",
      },
    },
    Category: {
      getAll: {
        type: "GET",
        endpoint: "/admin/category",
      },
      create: {
        type: "POST",
        endpoint: "/admin/category/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/category/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/category/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/category/",
      },
    },
    Tag: {
      getAll: {
        type: "GET",
        endpoint: "/admin/tag",
      },
      create: {
        type: "POST",
        endpoint: "/admin/tag/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/tag/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/tag/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/tag/",
      },
    },
    listiclesMain: {
      getAll: {
        type: "GET",
        endpoint: "/admin/listiclesMain",
      },
      create: {
        type: "POST",
        endpoint: "/admin/listiclesMain/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/listiclesMain/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/listiclesMain/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/listiclesMain/",
      },
    },
    Blog: {
      getAll: {
        type: "GET",
        endpoint: "/admin/blog",
      },
      create: {
        type: "POST",
        endpoint: "/admin/blog/",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/blog/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/blog/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/blog/",
      },
    },
    Flicker: {
      type: "GET",
      endpoint: "/admin/image/flicker",
    },
    SERP: {
      type: "GET",
      endpoint: "/admin/serpPhoto",
    },
    Unsplash: {
      type: "GET",
      endpoint: "/admin/image/flicker",
    },
    getAllCountry: {
      type: "GET",
      // endpoint: "/admin/countries",
      endpoint: "/admin/countries?isSearch=1",
      // endpoint: "/admin/beachMain/getAllCountry",
    },
    getAllState: {
      type: "GET",
      // endpoint: "/admin/beachMain/getState/",
      // endpoint: "/admin/states?countryId=",
      endpoint: "/admin/states?isSearch=1&countryId=",
    },
    getAllCity: {
      type: "GET",
      // endpoint: "/admin/beachMain/getCity/",
      // endpoint: "/admin/cities?countryId=:countryId&stateId=:stateId",
      endpoint:
        "/admin/cities?isSearch=1&countryId=:countryId&stateId=:stateId",
    },
    getAnalyticsAll: {
      type: "GET",
      endpoint: "/admin/general/analytics/country/state/city/beach",
    },
    getBeachBasicDetails: {
      type: "GET",
      endpoint: "/admin/beachBasicDetails/beach/:AllBeachId",
    },
    BeachBasicDetails: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachBasicDetails/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachBasicDetails",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/beachBasicDetails/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachBasicDetails/:id",
      },
    },
    BeachSummary: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachDescription/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachDescription",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/beachDescription/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachDescription/:id",
      },
    },
    BeachMain: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachMain/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachMain",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/beachMain/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachMain/:id",
      },
    },
    BeachDataPoints: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachCategory/beach/:AllBeachId",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachCategory",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/beachCategory/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachCategory/:id",
      },
    },
    BeachSEO: {
      getOne: {
        type: "GET",
        endpoint: "/admin/beachSEO",
      },
      create: {
        type: "POST",
        endpoint: "/admin/beachSEO",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/beachSEO/:id",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/beachSEO/:id",
      },
    },
    BeachPhoto: {
      IndexUpdate: {
        type: "PUT",
        endpoint: "/admin/image/indexUpdate/",
      },
      DeletePhoto: {
        type: "POST",
        endpoint: "/admin/image/remove",
      },
      AddPhoto: {
        type: "POST",
        endpoint: "/admin/image",
      },
      AddPhotoBulk: {
        type: "BULKPOST",
        endpoint: "/admin/image",
      },
      update: {
        type: "PUT",
        endpoint: "/admin/image/:id",
      },
    },
    // BeachPhoto: {
    //   getAll: {
    //     type: "GET",
    //     endpoint: "/admin/beachMain",
    //   },
    //   create: {
    //     type: "POST",
    //     endpoint: "/admin/beachMain",
    //   },
    //   update: {
    //     type: "PUT",
    //     endpoint: "/admin/beachMain/",
    //   },
    //   delete: {
    //     type: "DELETE",
    //     endpoint: "/admin/beachMain/",
    //   },
    //   getOne: {
    //     type: "GET",
    //     endpoint: "/admin/beachMain/",
    //   },
    // },
  },
};
export default CONSTANTS;
